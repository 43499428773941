import { Injectable } from '@angular/core';
import { EntityType } from '@shared-lib/modules/data/model/mnb-data-entity.model';
import { BehaviorSubject, Subject, of, Observable, combineLatest, EMPTY } from 'rxjs';
import { switchMap, map, withLatestFrom, tap, shareReplay, catchError } from 'rxjs/operators';
import { ApiViewService } from '@minubo-portal/modules/api/services/api-view.service';
import { PortalModalService } from '../../service/portal-modal.service';
import { PortalReportView } from '@minubo-portal/modules/api/models/api-report.model';
import { ReportSettings, ReportBaseFields, getReportType } from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { DataProviderSettings } from '@shared-lib/modules/data/model/mnb-data-data-provider.model';
import { HttpErrorResponse } from '@angular/common/http';
import { PortalDataProviderView } from '@minubo-portal/modules/api/models/api-data-provider.model';


@Injectable({
    providedIn: 'root'
})
export class ViewSharingService <T extends SettingsUnion> {
    // todo: remove once feature is live MNB-14969

    constructor(
        private apiViewService: ApiViewService,
        private modalService: PortalModalService,
    ) {

        const saveErrorHandler = this.getErrorHandler('GENERAL.VIEW_SHARING.EVENTS.VIEW_CREATED_ERROR');
        this.saveView$.pipe(
            withLatestFrom(this.availableViews$, this.activeEntity$, this.activeViewSettings$),
            switchMap(([viewTitle, availableViews, activeEntity, viewSettings]) => {
                while (availableViews.some(view => view.title === viewTitle)) {
                    viewTitle = this.getUniqueTitle(viewTitle);
                }
                switch (activeEntity.type) {
                    case EntityType.DATA_PROVIDER:
                        return this.apiViewService.createDataProviderView(activeEntity.id, viewTitle, viewSettings as DataProviderSettings).pipe(saveErrorHandler);
                    case EntityType.REPORT:
                        return this.apiViewService.createReportView(activeEntity.id, viewTitle, viewSettings as ReportSettings).pipe(saveErrorHandler);
                }
            }),
            tap(() => this.modalService.setStatus({ status: 'success', message: 'GENERAL.VIEW_SHARING.EVENTS.VIEW_CREATED_SUCCESS'})),
            tap(() => this.reloadViews$.next())
        ).subscribe();

        const deleteErrorHandler = this.getErrorHandler('GENERAL.VIEW_SHARING.EVENTS.VIEW_DELETED_ERROR', 'GENERAL.VIEW_SHARING.EVENTS.VIEW_DELETED_FORBIDDEN');
        this.deleteViewId$.pipe(
            withLatestFrom(this.activeEntity$),
            switchMap(([viewId, activeEntity]) => {
                switch (activeEntity.type) {
                    case EntityType.DATA_PROVIDER:
                        return this.apiViewService.deleteDataProviderView(activeEntity.id, viewId).pipe(deleteErrorHandler);
                    case EntityType.REPORT:
                        return this.apiViewService.deleteReportView(activeEntity.id, viewId).pipe(deleteErrorHandler);
                }
            }),
            tap(() => this.modalService.setStatus({ status: 'success', message: 'GENERAL.VIEW_SHARING.EVENTS.VIEW_DELETED_SUCCESS'})),
            tap(() => this.reloadViews$.next())
        ).subscribe();

        const updateErrorHandler = this.getErrorHandler('GENERAL.VIEW_SHARING.EVENTS.VIEW_UPDATED_ERROR', 'GENERAL.VIEW_SHARING.EVENTS.VIEW_UPDATED_FORBIDDEN');
        this.updateViewId$.pipe(
            withLatestFrom(this.activeEntity$, this.activeViewSettings$, this.availableViews$),
            switchMap(([viewId, activeEntity, viewSettings, availableViews]) => {
                const title = availableViews.find(view => view.id === viewId).title;
                switch (activeEntity.type) {
                    case EntityType.DATA_PROVIDER:
                        return this.apiViewService.updateDataProviderView(activeEntity.id, viewId, title, viewSettings as DataProviderSettings).pipe(updateErrorHandler);
                    case EntityType.REPORT:
                        return this.apiViewService.updateReportView(activeEntity.id, viewId, title, viewSettings as ReportSettings).pipe(updateErrorHandler);
                }
            }),
            tap(() => this.modalService.setStatus({ status: 'success', message: 'GENERAL.VIEW_SHARING.EVENTS.VIEW_UPDATED_SUCCESS'})),
            tap(() => this.reloadViews$.next())
        ).subscribe();

        this.activeEntity$.pipe(
            switchMap((activeEntity) => this.loadAvailableViews(activeEntity)),
        ).subscribe(availableViews => this.availableViewsSub$.next(availableViews));

        const reloadErrorHandler = this.getErrorHandler('GENERAL.VIEW_SHARING.EVENTS.VIEW_LOAD_ERROR');
        this.reloadViews$.pipe(
            withLatestFrom(this.activeEntity$),
            switchMap(([_, activeEntity]) => this.loadAvailableViews(activeEntity).pipe(reloadErrorHandler)),
        ).subscribe(availableViews => this.availableViewsSub$.next(availableViews));

    }

    private activeEntity$: BehaviorSubject<ActiveEntity> = new BehaviorSubject(null);

    private activeViewSettings$: BehaviorSubject<T> = new BehaviorSubject(null);

    private saveView$ = new Subject<string>();

    private deleteViewId$ = new Subject<number>();

    private updateViewId$ = new Subject<number>();

    private reloadViews$ = new Subject<void>();

    public isAvailable$: Observable<boolean> = this.activeEntity$.pipe(
        map(activeEntity => activeEntity != null),
    );

    private availableViewsSub$ = new BehaviorSubject<View[]>([]);
    public availableViews$ = this.availableViewsSub$.asObservable();

    public selectedViews$: Observable<boolean[]> = combineLatest([this.activeEntity$, this.availableViews$, this.activeViewSettings$]).pipe(
        map(([activeEntity, availableViews, activeViewSettings]) => availableViews.map(view => this.compareSettingsWrapper(view.settings as T, activeViewSettings, activeEntity.type))),
        shareReplay(1),
    );

    private loadView$ = new Subject<number>();

    public applyViewSettings$ = this.loadView$.pipe(
        withLatestFrom(this.availableViews$),
        map(([viewId, views]) => views.find(view => view.id === viewId)),
        map(view => view.settings),
    );

    public saveView(viewTitle: string) {
        this.saveView$.next(viewTitle);
    }

    public loadView(viewId: number) {
        this.loadView$.next(viewId);
    }

    public deleteView(viewId: number) {
        this.deleteViewId$.next(viewId);
    }

    public updateView(viewId: number) {
        this.updateViewId$.next(viewId);
    }

    public activateEntity(activeEntity: ActiveEntity) {
        this.activeEntity$.next(activeEntity);
        this.modalService.closeModal();
    }

    public setActiveViewSettings(viewSettings: T) {
        this.activeViewSettings$.next(viewSettings);
    }

    public deactivateEntity() {
        this.activeEntity$.next(null);
        this.activeViewSettings$.next(null);
        this.modalService.closeModal();
    }

    private loadAvailableViews(activeEntity: ActiveEntity): Observable<View[]> {
        if (activeEntity == null) {
            return of([]);
        }

        switch (activeEntity.type) {
            case EntityType.DATA_PROVIDER:
                return this.apiViewService.loadDataProviderViews(activeEntity.id);
            case EntityType.REPORT:
                return this.apiViewService.loadReportViews(activeEntity.id);
            default:
                return of([]);
        }
    }

    private compareSettingsWrapper(a: T, b: T, entityType: EntityType): boolean {
        if (!a || !b) {
            return false;
        }

        switch (entityType) {
            case EntityType.DATA_PROVIDER:
                return this.compareSettings(a as DataProviderSettings, b as DataProviderSettings);

            case EntityType.REPORT:
                const reportType = getReportType(a as ReportSettings);
                if (!b.hasOwnProperty(reportType)) {
                    return false;
                }
                const settingsA: ReportBaseFields = a[reportType];
                const settingsB: ReportBaseFields = b[reportType];
                return this.compareSettings(settingsA, settingsB);

            default:
                return false;
        }

    }

    private compareSettings(a: ReportBaseFields | DataProviderSettings, b: ReportBaseFields | DataProviderSettings): boolean {
        this.convertTimeFiltersToDate(a);
        this.convertTimeFiltersToDate(b);

        // if a saved view is not outdated (i.e. created with a former release), the keys of [a] and [b] should be the same
        for (const key of Object.keys(a)) {
            if (key === 'filters') {
                const equalFilters = this.compareFilters(a['filters'], b['filters']);
                if (!equalFilters) {
                    return false;
                }
                continue;
            }

            if (!b.hasOwnProperty(key)) {
                return false;
            }

            if (!deepEqual(a[key], b[key])) {
                return false;
            }
        }
        return true;
    }

    private compareFilters(filtersA: Array<any>, filtersB: Array<any>): boolean {
        if (!filtersB) {
            return false;
        }

        if (filtersA.length !== filtersB.length) {
            return false;
        }

        for (const filterA of filtersA) {
            const filterB = filtersB.find(f => f.attributeCode === filterA.attributeCode);
            if (!filterB) {
                return false;
            }
            if (filterA.typeCode !== filterB.typeCode) {
                return false;
            }
            if (!deepEqual(filterA.values, filterB.values)) {
                return false;
            }
        }
        return true;
    }

    private getErrorHandler(errorMessage: string, errorMessageForbidden?: string) {
        return catchError((error: HttpErrorResponse): Observable<any> => {
            if (!!errorMessageForbidden && error.status === 403) {
                this.modalService.setStatus({ status: 'error', message: errorMessageForbidden });
                return EMPTY;
            }
            this.modalService.setStatus({ status: 'error', message: errorMessage });
            return EMPTY;
        });
    }

    private getUniqueTitle(title: string): string {
        // add 2 to the end of the title or increase the number by 1 if one exists
        const match = title.match(/(.*)(\d+)$/);
        if (match) {
            const number = parseInt(match[2], 10);
            return match[1] + (number + 1);
        } else {
            return title + ' 2';
        }
    }

    private convertTimeFiltersToDate(viewSettings: ReportBaseFields | DataProviderSettings): ReportBaseFields | DataProviderSettings {
        if (viewSettings.timeFilter && viewSettings.timeFilter.from && viewSettings.timeFilter.from.date && typeof viewSettings.timeFilter.from.date === 'number') {
            viewSettings.timeFilter.from.date = new Date(viewSettings.timeFilter.from.date);
        }
        if (viewSettings.timeFilter && viewSettings.timeFilter.to && viewSettings.timeFilter.to.date && typeof viewSettings.timeFilter.to.date === 'number') {
            viewSettings.timeFilter.to.date = new Date(viewSettings.timeFilter.to.date);
        }
        if (viewSettings.comparisonFilter && viewSettings.comparisonFilter.fromDate && typeof viewSettings.comparisonFilter.fromDate === 'number') {
            viewSettings.comparisonFilter.fromDate = new Date(viewSettings.comparisonFilter.fromDate);
        }
        if (viewSettings.comparisonFilter && viewSettings.comparisonFilter.toDate && typeof viewSettings.comparisonFilter.toDate === 'number') {
            viewSettings.comparisonFilter.toDate = new Date(viewSettings.comparisonFilter.toDate);
        }

        return viewSettings;
    }

}


function deepEqual(obj1, obj2) {
    // Convert objects to plain objects if they are class instances
    const plainObj1 = obj1 instanceof Object ? JSON.parse(JSON.stringify(obj1)) : obj1;
    const plainObj2 = obj2 instanceof Object ? JSON.parse(JSON.stringify(obj2)) : obj2;

    // Check if both are not objects (primitive values)
    if (typeof plainObj1 !== 'object' || typeof plainObj2 !== 'object' || plainObj1 === null || plainObj2 === null) {
        return plainObj1 === plainObj2;
    }

    // Compare keys and values
    const keys1 = Object.keys(plainObj1);
    const keys2 = Object.keys(plainObj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    return keys1.every(key => keys2.includes(key) && deepEqual(plainObj1[key], plainObj2[key]));
}

type SettingsUnion = ReportSettings | DataProviderSettings;

type ActiveEntity = {
    id: number;
    type: EntityType;
};


type View = PortalReportView | PortalDataProviderView;
