import { Component, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { PortalModalService } from '@minubo-portal/modules/portal-modal/service/portal-modal.service';
import { ViewSharingService } from '@minubo-portal/modules/portal-modal/view-sharing/service/view-sharing.service';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { filter, map, tap, takeUntil, withLatestFrom, startWith, delay } from 'rxjs/operators';

@Component({
    selector: 'mnb-view-sharing-modal',
    templateUrl: './view-sharing-modal.component.html',
    styleUrls: ['./view-sharing-modal.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewSharingModalComponent implements OnDestroy {

    private destroy$ = new Subject<void>();

    public viewTitleFormControl = new FormControl(null, [Validators.required, Validators.minLength(1)]);

    private uiState$ = new BehaviorSubject<ViewSharingState>({ index: null, type: null });

    private saveIsReady$ = this.viewTitleFormControl.statusChanges.pipe(
        map(result => result === 'VALID'),
        startWith(false),
    );

    private eventCssClass$ = new BehaviorSubject<string>('status-none');

    model$ = combineLatest([
        this.uiState$,
        this.saveIsReady$,
        this.viewSharingService.availableViews$,
        this.viewSharingService.selectedViews$,
        this.eventCssClass$
    ]).pipe(
        map(([uiState, saveIsReady, availableViews, selectedViews, eventCssClass]) => ({uiState, saveIsReady, availableViews, selectedViews, eventCssClass}))
    );

    private saveView$ = new Subject<void>();

    public saveView() {
        this.saveView$.next();
    }

    public loadView(viewId: number) {
        this.viewSharingService.loadView(viewId);
    }

    public deleteView(viewId: number) {
        this.viewSharingService.deleteView(viewId);
        this.resetUiState();
    }

    public updateView(viewId: number) {
        this.viewSharingService.updateView(viewId);
        this.resetUiState();
    }

    public activateDeleteState(viewId: number) {
        this.uiState$.next({ index: viewId, type: 'delete' });
    }

    public activateSaveState(viewId: number) {
        this.uiState$.next({ index: viewId, type: 'save' });
    }

    public resetUiState() {
        this.uiState$.next({ index: null, type: null });
    }

    constructor(
        public modalService: PortalModalService,
        public viewSharingService: ViewSharingService<unknown>,
    ) {
        this.saveView$.pipe(
            withLatestFrom(this.saveIsReady$),
            filter(([_, saveIsReady]) => saveIsReady),
            tap(() => {
                this.viewSharingService.saveView(this.viewTitleFormControl.value);
                this.viewTitleFormControl.setValue('');
                this.resetUiState();
            }),
            takeUntil(this.destroy$)
        ).subscribe();

        this.modalService.statusEvent$.pipe(
            tap(() => this.eventCssClass$.next('status-none')),
            delay(50),
            map(status => {
                switch (status.status) {
                    case 'success':
                        return 'status-success';
                    case 'error':
                        return 'status-error';
                    default:
                        return 'status-none';
                }
            }),
            tap(cssClass => this.eventCssClass$.next(cssClass)),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}

type ViewSharingState = {
    index: number;
    type: 'delete' | 'save';
};
