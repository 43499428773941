import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { MnbCoreModule } from '../core/mnb-core.module';
import { MnbQuickTimeFilterComponent } from './components/quick-time-filter/mnb-quick-time-filter.component';
import { MnbReactiveQuickTimeFilterComponent } from './components/quick-time-filter/mnb-reactive-quick-time-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MnbQuickFilterComponent } from './components/quick-filter/mnb-quick-filter.component';
import { MnbModelModule } from '../model/mnb-model.module';
import { MnbQuickFilterOverviewComponent, OverviewFilterLabelPipe, OverviewTimeFilterLabelPipe, } from '@shared-lib/modules/filters/components/quick-filter-overview/mnb-quick-filter-overview.component';

@NgModule({
    declarations: [
        MnbQuickTimeFilterComponent,
        MnbReactiveQuickTimeFilterComponent,
        MnbQuickFilterComponent,
        MnbQuickFilterOverviewComponent,

        OverviewFilterLabelPipe,
        OverviewTimeFilterLabelPipe,
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MnbCoreModule,
        MnbModelModule
    ],
    entryComponents: [],
    providers: [],
    exports: [
        MnbQuickTimeFilterComponent,
        MnbReactiveQuickTimeFilterComponent,
        MnbQuickFilterComponent,
        MnbQuickFilterOverviewComponent
    ]
})
export class MnbFiltersModule {
}
