import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AppHeader } from '@minubo-portal/models/app-header.model';
import { PortalReport } from '@minubo-portal/modules/api/models/api-report.model';
import { ApiReportService } from '@minubo-portal/modules/api/services/api-report.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { getReportType, ReportSettings } from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { LocalSettingsStore } from '@minubo-portal/utils/local-settings-store.util';
import { ApiTrackingService } from '@minubo-portal/modules/api/services/api-tracking.service';
import { tryParseFilters } from '@shared-lib/utils/route.utils';
import deepEqual from 'deep-equal';
import { ViewSharingService } from '@minubo-portal/modules/portal-modal/view-sharing/service/view-sharing.service';
import { EntityType } from '@shared-lib/modules/data/model/mnb-data-entity.model';


@Component({
    templateUrl: './reports-report-page.component.html'
})
export class ReportsReportPageComponent implements OnInit, OnDestroy {

    constructor(
        private route: ActivatedRoute,
        private viewSharingService: ViewSharingService<ReportSettings>,
    ) {
        this.viewSharingService.activateEntity({
            id: this.route.snapshot.params['reportId'],
            type: EntityType.REPORT
        });
    }

    public context$: Observable<ReportsReportPageContext>;


    private destroy$ = new Subject<void>();

    ngOnInit(): void {

        this.context$ = this.route.data.pipe(
            map((data: {context: ReportsReportPageContext}) => data.context)
        );

    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.viewSharingService.deactivateEntity();
    }
}

@Injectable()
export class ReportsReportPageContextResolver implements Resolve<ReportsReportPageContext> {


    constructor(
        private apiReportService: ApiReportService,
        private trackingService: ApiTrackingService,
        private settingsStore: LocalSettingsStore<ReportSettings>
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<ReportsReportPageContext> {
        const reportId = route.params['reportId'];
        const queryFilters = tryParseFilters<ReportSettings>(route);

        const result = this.apiReportService.loadReport(reportId).pipe(
            map(portalReport => {
                (<AppHeader>route.data.header).title = portalReport.title;
                if (queryFilters) {
                    const mergedSettings = mergeSettings(queryFilters, portalReport.settings);
                    this.settingsStore.store('report', reportId, mergedSettings);
                }
                const localSettings = this.settingsStore.load('report', reportId);
                return {
                    report: portalReport,
                    localSettings: localSettings
                };
            }),
        );
        result.subscribe(report => {
            const parameters = {
                'reportId': report.report.id,
                'reportTypeCode': report.report.typeCode,
                'reportTitle': report.report.title,
            };
            this.trackingService.tracking('report', 'view', parameters);
        });
        return result;
    }
}

export type ReportsReportPageContext = {

    report: PortalReport;
    localSettings: ReportSettings | null;

};

/**
 * Merges a partial settings object into a source settings object, creating a new object as result
 * @param settings the settings to merge
 * @param source the source settings to merge into
 * @returns the merged settings
 */
function mergeSettings(settings: ReportSettings, source: ReportSettings): ReportSettings {
    if (!source) {
        return null;
    }
    if (!settings) {
        return source;
    }
    const merged = {...source};

    const reportType = getReportType(settings);
    const typedSettings = settings[reportType];
    const typedSource = source[reportType];

    Object.keys(typedSettings).forEach(key => {
        const settingValue = typedSettings[key];
        const sourceValue = typedSource[key];
        if (!deepEqual(sourceValue, settingValue)) {
            merged[reportType][key] = settingValue;
        }
    });
    return merged;
}
