import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ViewSharingModalComponent } from './view-sharing/components/view-sharing-modal.component';
import { PortalModalService } from './service/portal-modal.service';
import { ViewSharingService } from './view-sharing/service/view-sharing.service';
import { MnbCoreModule } from '@shared-lib/modules/core/mnb-core.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        ViewSharingModalComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MnbCoreModule,
        TranslateModule,
    ],
    exports: [
        ViewSharingModalComponent
    ],
    providers: [
        PortalModalService,
        ViewSharingService,
    ]
})

export class PortalModalModule { }
