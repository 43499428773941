import { Injectable } from '@angular/core';
import { switchMap, withLatestFrom, tap, delay, filter } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class PortalModalService {

    public openModal$ = new BehaviorSubject<boolean>(false);

    private statusEventSub$ = new BehaviorSubject<StatusEvent>({ status: null, message: null });
    public statusEvent$ = this.statusEventSub$.asObservable();

    constructor(
    ) {
        this.statusEventSub$.pipe(
            filter(val => val.status != null),
            switchMap(() => of(null).pipe(
                delay(3000),
                withLatestFrom(this.statusEvent$),
                tap(([_, oldStatus]) => this.statusEventSub$.next({ status: null, message: oldStatus.message }))
            ))
        ).subscribe();

    }

    public setStatus(status: StatusEvent) {
        this.statusEventSub$.next(status);
    }

    public toggleModal() {
        this.resetStatus();
        this.openModal$.next(!this.openModal$.value);
    }

    public closeModal() {
        this.resetStatus();
        this.openModal$.next(false);
    }

    private resetStatus() {
        this.statusEventSub$.next({ status: null, message: null });
    }
}

export type StatusEvent = {
    status: 'success' | 'error';
    message: string;
};
