import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AppHeader } from './models/app-header.model';
import { PortalLogin, PortalSession } from './modules/api/models/api-login.model';
import { ApiAuthService } from './modules/api/services/api-auth.service';
import { ApiSessionService } from './modules/api/services/api-session.service';
import { PortalInstanceConfig, PortalInstanceService } from './services/portal-instance.service';
import { PortalEtlStatus, EtlStatusCode } from './modules/api/models/api-etl.model';
import { ApiEtlService } from './modules/api/services/api-etl.service';
import { TraceClassDecorator } from '@sentry/angular';
import { PortalModalService } from './modules/portal-modal/service/portal-modal.service';
import { ViewSharingService } from './modules/portal-modal/view-sharing/service/view-sharing.service';

@TraceClassDecorator()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    private destroy$ = new Subject<void>();

    public year: number;

    public initializedState$: Observable<boolean> = this.apiSessionService.initializedState$;

    public loginDetails$: Observable<PortalLogin> = this.authService.getLogin();
    public session$: Observable<PortalSession> = this.authService.getSession();

    public header: Observable<AppHeader>;
    public tosLink: string;
    public privacyLink: string;

    public etlStatus$: Subject<PortalEtlStatus> = new Subject();
    public etlStatusModel$: Observable<EtlStatusModel>;

    public currentLanguage: string;
    public languageOptions = [{
        code: 'de', label: 'DE'
    }, {
        code: 'en', label: 'EN'
    }];

    public instanceConfig: PortalInstanceConfig;

    @HostBinding('class')
    public get cssClass(): string {
        return this.instanceConfig.cssClass || '';
    }

    constructor(
        private authService: ApiAuthService,
        private apiSessionService: ApiSessionService,
        private translateService: TranslateService,
        private etlService: ApiEtlService,
        private title: Title,
        portalInstanceService: PortalInstanceService,
        router: Router,
        route: ActivatedRoute,
        public modalService: PortalModalService,
        public viewSharingService: ViewSharingService<unknown>,
    ) {

        this.year = new Date().getFullYear();
        this.instanceConfig = portalInstanceService.instanceConfig;
        this.title.setTitle(this.instanceConfig.name);
        this.header = router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => route.snapshot),
            map(routeSnapshot => {
                while (routeSnapshot.firstChild) {
                    routeSnapshot = routeSnapshot.firstChild;
                }
                return routeSnapshot;
            }),
            map(routeSnapshot => routeSnapshot.data && routeSnapshot.data.header ? routeSnapshot.data.header : null)
        );
    }

    ngOnInit(): void {


        this.translateService.onLangChange.subscribe((language) => {
            this.currentLanguage = language.lang;

            if (this.currentLanguage !== 'de') {
                this.tosLink = 'https://www.minubo.com/en/agb';
                this.privacyLink = 'https://www.minubo.com/en/privacy-policy';
            } else {
                this.tosLink = 'https://www.minubo.com/de/agb';
                this.privacyLink = 'https://www.minubo.com/de/datenschutzerklaerung';
            }
        });

        this.apiSessionService.sessionState$.pipe(
            takeUntil(this.destroy$)
        ).subscribe((session) => {
            if (session.authenticated) {
                this.etlService.getCurrentDay().subscribe((status) => {
                    this.etlStatus$.next(status);
                });
            }
        });

        this.etlStatusModel$ = this.etlStatus$.pipe(map(
            etlStatus => {

                let color = 'GREY';
                let description = 'MODULE.STT.ETL.HEADER_DESCRIPTION.SIMPLE_NOT_DONE';
                let statusTime = null;

                if (etlStatus) {
                    switch (etlStatus.statusCode) {
                        case EtlStatusCode.DONE:
                            color = 'GREEN';
                            description = 'MODULE.STT.ETL.HEADER_DESCRIPTION.DONE';
                            if (etlStatus.finishingDateTime) {
                                statusTime = etlStatus.finishingDateTime;
                            }
                            break;
                        case EtlStatusCode.ONGOING:
                            description = 'MODULE.STT.ETL.HEADER_DESCRIPTION.ONGOING';
                            if (etlStatus.targetDateTime) {
                                statusTime = etlStatus.targetDateTime;
                            }
                            break;
                    }
                }

                return {color, description, statusTime};
            }
        ));
    }

    public logout() {
        this.authService.logout();
    }

    public changeLanguage(languageCode: string) {
        this.apiSessionService.changeLanguage(languageCode);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}

export type EtlStatusModel = {
    color: string;
    description: string;
    statusTime: number;
};
