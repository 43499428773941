import { Injectable } from '@angular/core';
import { ApiService, Resources } from './api.service';
import { Observable } from 'rxjs';
import { PortalReportView } from '@minubo-portal/modules/api/models/api-report.model';
import { ReportSettings } from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { PortalDataProviderView } from '../models/api-data-provider.model';
import { DataProviderSettings } from '@shared-lib/modules/data/model/mnb-data-data-provider.model';


@Injectable()
export class ApiViewService {

    constructor(
        private apiService: ApiService
    ) { }

    public loadReportViews(reportId: number): Observable<PortalReportView[]> {
        return this.apiService.query(Resources.REPORT_VIEW, { reportId }, true);
    }

    public loadDataProviderViews(dataProviderId: number): Observable<PortalDataProviderView[]> {
        return this.apiService.query(Resources.DATA_PROVIDER_VIEW, { dataProviderId }, true);
    }

    public loadReportView(reportId: number, viewId: number): Observable<PortalReportView> {
        return this.apiService.get(Resources.REPORT_VIEW, { reportId, viewId }, true);
    }

    public loadDataProviderView(dataProviderId: number, viewId: number): Observable<PortalDataProviderView> {
        return this.apiService.get(Resources.DATA_PROVIDER_VIEW, { dataProviderId, viewId }, true);
    }

    public createReportView(reportId: number, title: string, settings: ReportSettings): Observable<PortalReportView> {
        return this.apiService.post(Resources.REPORT_VIEW, { title, settings }, { reportId }, true);
    }

    public createDataProviderView(dataProviderId: number, title: string, settings: DataProviderSettings): Observable<PortalDataProviderView> {
        return this.apiService.post(Resources.DATA_PROVIDER_VIEW, { title, settings }, { dataProviderId }, true);
    }

    public updateReportView(reportId: number, viewId: number, title: string, settings: ReportSettings): Observable<PortalReportView> {
        return this.apiService.post(Resources.REPORT_VIEW, { title, settings }, { reportId, viewId }, true);
    }

    public updateDataProviderView(dataProviderId: number, viewId: number, title: string, settings: DataProviderSettings): Observable<PortalDataProviderView> {
        return this.apiService.post(Resources.DATA_PROVIDER_VIEW, { title, settings }, { dataProviderId, viewId }, true);
    }

    public deleteReportView(reportId: number, viewId: number): Observable<PortalReportView> {
        return this.apiService.delete(Resources.REPORT_VIEW, { reportId, viewId }, true);
    }

    public deleteDataProviderView(dataProviderId: number, viewId: number): Observable<PortalDataProviderView> {
        return this.apiService.delete(Resources.DATA_PROVIDER_VIEW, { dataProviderId, viewId }, true);
    }

}
